import React, { useState, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { cn } from '@/lib/utils';

import Button from '../atoms/button';
import Icon from '../atoms/icon';
import { useMemo } from 'react';

const Input = React.forwardRef(
	(
		{
			type,
			className,
			button,
			readOnly,
			id,
			value,
			onChange,
			iconComponent,
			currencySymbol,
			showCurrencySymbol,
			isForDateTable,
			onBlur,
			onFocus,
			handleClearSearch,
			autoComplete,
			...props
		},
		ref
	) => {
		const [showSearchInputCloseBtn, setShowSearchInputCloseBtn] = useState(false);
		const [inputValue, setInputValue] = useState(value ? value : '');
		const [showPassword, setShowPassword] = useState(false);

		useEffect(() => {
			setInputValue(value ? value : '');
		}, [value]);

		useEffect(() => {
			// only fire change when form value and internal state out of sync
			if (value === inputValue) return;

			onChange && onChange({ target: { value: inputValue } });
		}, [inputValue]);

		useImperativeHandle(
			ref,
			() => {
				return {
					setShowSearchInputCloseBtn: setShowSearchInputCloseBtn,
				};
			},
			[setShowSearchInputCloseBtn]
		);

		const togglePassword = (e, inputId) => {
			e.preventDefault();

			if (!showPassword) {
				setShowPassword(true);
			} else {
				setShowPassword(false);
			}
		};

		const searchOnChange = (e) => {
			if (e.target.value) {
				setShowSearchInputCloseBtn(true);
			} else if (showSearchInputCloseBtn) {
				setShowSearchInputCloseBtn(false);
			}
		};

		const htmlInputType = useMemo(() => {
			switch (type) {
				case 'currency':
					return 'number';
				case 'datepicker-input':
					return 'text';
				default:
					return type;
			}
		}, [type]);

		const hideCloseBtnWithDebounce = debounce(() => {
			console.log('debounce');
			setShowSearchInputCloseBtn(false);
		}, 200);

		return (
			<div className="flex w-full gap-2">
				<div className="relative w-full">
					<input
						type={showPassword ? 'text' : htmlInputType}
						className={cn(
							'flex h-10 w-full rounded-md border border-formOutline bg-background py-2 text-base text-eis-black ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-formOutlineDark disabled:cursor-not-allowed disabled:opacity-50',
							!readOnly &&
								'focus:border-formOutline focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-formOutlineDark focus-visible:ring-offset-2',
							type === 'search' ? 'search-input pr-3 pl-9 w-full relative z-0' : 'px-3',
							iconComponent && 'pl-3 pr-9',
							// Note: for password's "show" button
							type === 'password' && 'relative',
							htmlInputType === 'number' && showCurrencySymbol ? (isForDateTable ? 'pl-6 h-8 w-[147px] text-xs' : 'pl-7') : '',
							className,
							readOnly && 'opacity-50 focus:cursor-not-allowed focus:ring-0  p-0'
						)}
						step={type === 'currency' ? '0.01' : null}
						ref={ref}
						autoComplete={autoComplete}
						value={inputValue}
						onChange={(e) => {
							// Note: for search input
							type === 'search' && searchOnChange(e);

							// Note: update all inputs' value
							setInputValue(e.target.value);
						}}
						onBlur={(e) => {
							// handle currency formatting
							if (type === 'currency') {
								// strip any illegal characters
								let currencyValue = parseFloat(String(e.target.value).replace(/[^0-9.-]+/g, ''));
								if (isNaN(currencyValue)) currencyValue = '';

								// apply trailing digits to value
								if (currencyValue !== '')
									currencyValue = currencyValue.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/[^0-9.-]+/g, '');

								// trigger internal & field data changes
								setInputValue(currencyValue);
								// onChange({ target: { value: currencyValue } });
							}

							if (type === 'search' && showSearchInputCloseBtn) {
								hideCloseBtnWithDebounce();
							}

							// trigger external blur handler
							if (typeof onBlur === 'function') onBlur(e);
						}}
						{...(type === 'search' && {
							onFocus: (e) => {
								if (e.target.value && !showSearchInputCloseBtn) {
									setShowSearchInputCloseBtn(true);
								}
							},
						})}
						{...props}
						// Note: for password's "show" button
						id={id}
					/>

					{type === 'search' && <Icon name="search" className="absolute top-3 left-3 text-formOutlineDark" />}

					{iconComponent && iconComponent}

					{/* Note: add customized clear button for search input (it only shows when user has typed something...) */}
					{type === 'search' && showSearchInputCloseBtn && inputValue && (
						<div className="absolute w-6 h-6 top-2 right-3 z-[1]">
							<Button
								type="button"
								size="none"
								variant="icon"
								icon="close-circle"
								className={cn('text-eis-black hover:bg-transparent hover:opacity-75')}
								iconClassName="w-6 h-6"
								onClick={(eve) => {
									eve.preventDefault();
									eve.stopPropagation();
									setInputValue('');
									handleClearSearch();
								}}
							/>
						</div>
					)}

					{type === 'password' && (
						<Button variant="underline" size="none" onClick={(e) => togglePassword(e, id)} className="absolute top-2 right-3">
							{showPassword ? 'Hide' : 'Show'}
						</Button>
					)}

					{htmlInputType === 'number' && showCurrencySymbol && (
						<span className={cn('absolute text-formDarkBlueGrey top-2 left-3', isForDateTable && 'text-xs')}>{currencySymbol}</span>
					)}
				</div>

				{button && (
					<Button variant="primary" className="capitalize shrink-0">
						{button}
					</Button>
				)}
			</div>
		);
	}
);
Input.displayName = 'Input';

Input.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	button: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func,
	iconComponent: PropTypes.element,
	currencySymbol: PropTypes.string,
};

Input.defaultProps = {
	type: 'text',
	className: '',
	button: null,
	value: '',
	currencySymbol: '£',
	onChange: () => {},
};

export { Input };
